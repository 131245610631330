'use client';

// Link component from @chakra-ui/next-js creates an error here for local dev

/* eslint-disable no-restricted-imports */
import { Flex, Heading, Link, Text } from '@chakra-ui/react';
import NextLink from 'next/link';

import { uiRoutes } from '~/routes';

export default function NotFound() {
  return (
    <Flex
      height="100vh"
      alignItems="center"
      justifyContent="center"
      flexDir="column"
      gap="6"
      width="50vw"
      marginX="auto"
    >
      <Heading as="h1" size="md">
        Not Found
      </Heading>

      <Text>Could not find the requested resource.</Text>

      <Link as={NextLink} href={uiRoutes.root()}>
        Return Home
      </Link>
    </Flex>
  );
}
